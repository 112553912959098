// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/forms/toggle-switch.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/forms/toggle-switch.tsx");
  import.meta.hot.lastModified = "1726706773000";
}
// REMIX HMR END

export const ToggleSwitch = ({
  onChangeHandler,
  darkMode,
  disabled = false
}) => {
  return <label className="relative flex justify-between items-center group p-2 text-xl">
      <input type="checkbox" className={"absolute left-1/2 -translate-x-1/2 w-full h-full peer appearance-none rounded-md"} onChange={onChangeHandler} checked={darkMode} disabled={!!disabled} />
      <span className={"w-11 h-6 flex items-center flex-shrink-0 ml-4 p-1 bg-gray-300 rounded-full duration-300 ease-in-out peer-checked:bg-[#F28C28] after:w-5 after:h-5 after:bg-white peer-checked:after:bg-white after:rounded-full after:shadow-md after:duration-300 peer-checked:after:translate-x-4 group-hover:after:translate-x-1 " + (disabled ? ' opacity-50' : '')}> </span>
    </label>;
};
_c = ToggleSwitch;
var _c;
$RefreshReg$(_c, "ToggleSwitch");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;